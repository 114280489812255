import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { FC, useState } from "react";
import { Form, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { Task, TaskStatus } from "../../types";
import { AppointmentInputType } from "./type";
import {
  getScheduledDateTime,
  secondsToTime,
  setScheduledDateTime,
} from "@/components/utils/date-time-utils";
import { isFCFS } from "@/components/utils/sched-method-utils";
import { timezones } from "@/components/utils/date-time-utils";

export const AppointmentDateTimeInput = ({
  task,
  doInitialize = true,
  type,
  disabled,
  onUpdate,
  onNullUpdate,
  className,
}: {
  task: Task;
  doInitialize?: boolean;
  type: AppointmentInputType;
  disabled?: boolean;
  onUpdate?: () => void;
  onNullUpdate?: () => void;
  className?: string;
}) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const isDate = type === AppointmentInputType.DATE;
  const fieldName =
    type === AppointmentInputType.EARLY_TIME
      ? "earlyScheduledAt"
      : "scheduledAt";
  const openHours =
    task.facility?.openingDays?.[
      new Date(task.scheduledAt)
        .toLocaleString("us", { weekday: "short", timeZone: task.timezone })
        .toLowerCase()
    ];
  const [showTimeWarning, setShowTimeWarning] = useState(false);

  const getApptTime = () => {
    if (!doInitialize) {
      return "";
    } else if (
      type === AppointmentInputType.EARLY_TIME &&
      !task.earlyScheduledAt
    ) {
      return secondsToTime(openHours?.min || 28800);
    } else {
      return getScheduledDateTime(
        task[fieldName] || task.scheduledAt,
        task.timezone,
        isDate,
      );
    }
  };

  const [taskTime, setTaskTime] = useState(getApptTime());
  let minTime: string = secondsToTime(openHours?.min),
    maxTime: string = secondsToTime(openHours?.max);

  if (task.status === TaskStatus.NO_ACTION) {
    disabled = true;
  } else if (isFCFS(task.schedulingMethod)) {
    if (type === AppointmentInputType.EARLY_TIME) {
      maxTime = getScheduledDateTime(task.scheduledAt, task.timezone, false);
    } else if (type === AppointmentInputType.TIME && task.earlyScheduledAt) {
      minTime = getScheduledDateTime(
        task.earlyScheduledAt,
        task.timezone,
        false,
      );
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateTaskTime(e);
    }
  };

  const updateTaskTime = (e) => {
    const value = e.target.value;
    let newDate = setScheduledDateTime(
      task[fieldName] || task.scheduledAt,
      task.timezone,
      value,
      isDate,
    );

    if (!task[fieldName] || task[fieldName].getTime() != newDate.getTime()) {
      task[fieldName] = newDate;
      updateTask(task);
    } else {
      onNullUpdate();
    }
    setShowTimeWarning(false);
    return value;
  };

  const updateTask = async (task) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({
        task: { id: task.id, [fieldName]: task[fieldName] },
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if ([200].includes(response.status)) {
      railsToast({ info: "Task has been updated!" }, 200);
      task.taskUpdates.push(...(await response.json()));
      onUpdate();
    } else railsToast(await response.json(), response.status);
  };

  const handleOnChange = (e) => {
    setTaskTime(e.target.value);
    setShowTimeWarning(!e.target.checkValidity());
  };

  return (
    <Form className={className}>
      <Form.Group className="position-relative">
        <OverlayTrigger
          placement="top"
          show={showTimeWarning}
          overlay={<Tooltip id="time-tooltip">outside facility hours!</Tooltip>}
        >
          <Form.Control
            type={isDate ? "date" : "time"}
            required
            disabled={disabled}
            min={isDate ? `${new Date().getFullYear() - 20}-01-01` : minTime}
            max={isDate ? `${new Date().getFullYear() + 50}-12-31` : maxTime}
            style={isDate ? { width: "155px" } : null}
            value={taskTime}
            onChange={handleOnChange}
            onFocus={(e) => setShowTimeWarning(!e.target.checkValidity())}
            onBlur={updateTaskTime}
            onKeyDown={handleKeyDown}
          />
        </OverlayTrigger>
      </Form.Group>
    </Form>
  );
};

type AppointmentInputProps = {
  task: Task;
  doInitialize?: boolean;
  onUpdate?: () => void;
  onNullUpdate?: () => void;
  disabled?: boolean;
};

export const AppointmentInput: FC<AppointmentInputProps> = ({
  task,
  doInitialize = true,
  onUpdate = () => {},
  onNullUpdate = () => {},
  disabled = false,
}) => {
  return (
    <Stack direction="horizontal" gap={2}>
      <span className="fw-bold">Appointment Time:</span>
      <AppointmentDateTimeInput
        task={task}
        doInitialize={true}
        type={AppointmentInputType.DATE}
        onUpdate={onUpdate}
        onNullUpdate={onNullUpdate}
        disabled={disabled}
      />
      {!isFCFS(task.schedulingMethod) ? (
        <></>
      ) : (
        <AppointmentDateTimeInput
          task={task}
          doInitialize={doInitialize}
          type={AppointmentInputType.EARLY_TIME}
          onUpdate={onUpdate}
          onNullUpdate={onNullUpdate}
          disabled={disabled}
        />
      )}
      <AppointmentDateTimeInput
        task={task}
        doInitialize={doInitialize}
        type={AppointmentInputType.TIME}
        onUpdate={onUpdate}
        onNullUpdate={onNullUpdate}
        disabled={disabled}
      />
      <div className="py-2 px-1">{timezones[task.timezone]}</div>
    </Stack>
  );
};
