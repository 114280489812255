import { useAuth } from "@clerk/clerk-react";

export const isEase = () => {
  const { orgId } = useAuth();
  return (orgId === EASE_WALMART_CLERK_ID) || (orgId === EASE_BODYARMOR_CLERK_ID)
}

export const isCapstone = () => {
  const { orgId } = useAuth();
  return orgId === CAPSTONE_CLERK_ID
}

export const isZengistics = () => {
  const { orgId } = useAuth();
  return orgId === ZENGISTICS_CLERK_ID
}