import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "./theme-context";
import { dark } from "@clerk/themes";
import { FC, ReactNode, useEffect, useState } from "react";
type ClerkContextProps = {children: ReactNode}

const ClerkContext: FC<ClerkContextProps> = ({children}) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [theme]);

  if (isLoading) {
    return <></>;
  }
  return (
    <ClerkProvider
      appearance={{ baseTheme: theme === 'dark' ? dark : null }}
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      navigate={(to) => navigate(to)}
    >
      <SignedIn>
        {children}
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  );
};

export default ClerkContext;