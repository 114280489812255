import styles from "./contacts.module.css";
import { getContactIcon } from "@/components/utils/contact-utils";
import { Contact, ContactType } from "@/components/contacts/type";

const ContactView = ({
  contact,
  disabled,
  onShow,
  onRemove,
  onEdit,
}: {
  contact: Contact;
  disabled?: boolean;
  onShow?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
}) => {
  return (
    <div className="contact border rounded p-1 me-1 d-flex">
      <i className={"me-1 " + getContactIcon(contact.contactType)} />
      {contact.contactType === ContactType.PORTAL ? (
        <>
          <a
            role="button"
            className={`primary ${styles.portal_contact}`}
            onClick={() => onShow()}
          >
            {JSON.parse(contact.contact).name}
          </a>
          <div>
            {disabled ? (
              <></>
            ) : (
              <i
                className={"ms-2 me-1 bi bi-pencil " + styles.edit_btn}
                role="button"
                onClick={async () => onEdit()}
              ></i>
            )}
          </div>
        </>
      ) : (
        <>
          {contact.contact}
          <div>
            {disabled ? (
              <></>
            ) : (
              <i
                className={"h5 ms-1 bi bi-x-square " + styles.close_btn}
                role="button"
                onClick={async () => onRemove()}
              ></i>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContactView;
