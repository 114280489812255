import { FC, useEffect, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { Task } from "../../types";
import updateTask from "../../update-task";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";

type ConfirmationInputProps = {
  task: Task;
  taskUpdated: () => void;
  onUpdate?: () => void;
  disabled?: boolean;
  doInitialize?: boolean;
};
const ConfirmationInput: FC<ConfirmationInputProps> = ({
  task,
  taskUpdated,
  onUpdate = () => {},
  disabled = false,
  doInitialize = true,
}) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [confirmNum, setConfirmNum] = useState<string>(
    doInitialize ? task.confirmNum : null,
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateConfirmNum();
    }
  };

  const updateConfirmNum = () => {
    if (confirmNum != task.confirmNum) {
      updateTask(
        task,
        { confirmNum: confirmNum },
        "Appointment confirmation number has been updated!",
        getToken,
        railsToast,
        taskUpdated,
        () => {
          task.confirmNum = confirmNum;
          onUpdate();
        },
      );
    }
  };
  return (
    <Stack direction="horizontal" gap={2}>
      <div className="fw-bold">Confirmation Number:</div>
      <Form.Control
        type="text"
        value={confirmNum}
        className="me-2"
        onChange={(e) => setConfirmNum(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={updateConfirmNum}
        style={{ maxWidth: "200px" }}
        disabled={disabled}
      />
    </Stack>
  );
};

export default ConfirmationInput;
