import { Outlet } from "react-router-dom";
import Shell from "./shell";
import { useTheme } from "./theme-context";
import { useEffect, useState } from "react";
import ClerkContext from "./clerk-context";

const RootLayout = () => {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [theme]);

  if (isLoading) {
    return <></>;
  }
  return (
    <ClerkContext>
      <Shell>
        <Outlet />
      </Shell>
    </ClerkContext>
  );
};

export default RootLayout;