import "@mantine/core/styles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./root-layout";
import OrganizationSettings from "../organizations/settings";
import EmailSignatureShow from "../email-signatures/show";
import TaskShow from "../tasks/show";
import ShipmentSearch from "../shipments/search";
import FacilitySearch from "../facilities/search";
import ResourceSearch from "../resources/search";
import ShipmentShow from "@/components/shipments/show";
import SopShow from "../sops/show";
import { TaskStatus } from "../tasks/types";
import Analytics from "../analytics";
import FacilityShow from "../facilities/show";
import SessionShow from "../sessions/show";
import NotFound from "./not-found";

const Routes = () => {
  const rootRouter = createBrowserRouter([
    {
      element: <RootLayout />,
      children: [
        { path: "/", element: <ShipmentSearch /> },
        { path: "/reports", element: <Analytics /> },
        { path: "/tasks/:taskId", element: <TaskShow /> },
        { path: "/sops", element: <SopShow /> },
        { path: "/shipments", element: <ShipmentSearch /> },
        { path: "/shipments/:shipmentId", element: <ShipmentShow /> },
        {
          path: "/escalations",
          element: <ShipmentSearch status={TaskStatus.ESCALATED} />,
        },
        { path: "/facilities", element: <FacilitySearch /> },
        { path: "/resources", element: <ResourceSearch /> },
        { path: "/facilities/:facilityId", element: <FacilityShow /> },
        { path: "/organization_settings", element: <OrganizationSettings /> },
        { path: "/email_signature", element: <EmailSignatureShow /> },
        {
          path: "/tasks/:taskId/sessions/:sessionId",
          element: <SessionShow />,
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return <RouterProvider router={rootRouter} />;
};

export default Routes;
