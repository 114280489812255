import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "rrweb-player/dist/style.css";
import rrwebPlayer from "rrweb-player";
import { Task, TaskStatus } from "@/components/tasks/types";
import TaskPreview from "@/components/common/tasks/task-preview";
import { AppointmentInput } from "@/components/tasks/show/appointment/appointment_input";
import ConfirmationInput from "@/components/tasks/show/confirmation/confirmation_input";

const SessionShow = () => {
  const { getToken } = useAuth();
  const { sessionId, taskId } = useParams();
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [loadingTask, setLoadingTask] = useState<boolean>(true);
  const [appointmentSet, setAppointmentSet] = useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = useState<string>();
  const [task, setTask] = useState<Task>(null);
  const [confirmNum, setConfirmNum] = useState<string>(null);
  const [apptTimeIsChanged, setApptTimeIsChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSession();
    fetchTask(taskId);
  }, []);

  useEffect(() => {
    setConfirmNum(task?.confirmNum);
  }, [appointmentSet]);

  const getSession = async () => {
    if (sessionId && !iframeUrl) {
      setLoadingSession(true);
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/sessions/${sessionId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setLoadingSession(false);
        const json = await response.json();
        if (json.url) {
          setIframeUrl(json.url);
        } else {
          new rrwebPlayer({
            target: document.getElementById("player"),
            props: {
              autoPlay: false,
              events: JSON.parse(json?.recording || "[]"),
              skipInactive: true,
            },
          });
          // console.log(json.recording);
        }
      } else {
        navigate("/shipments");
      }
    }
  };

  const fetchTask = async (id: string) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    console.log("fetched task", json);

    const status = response.status;
    if ([200, 304].includes(status)) {
      setTask({
        ...json,
        createdAt: new Date(json.createdAt),
        scheduledAt: new Date(json.scheduledAt),
        earlyScheduledAt: new Date(json.earlyScheduledAt || json.scheduledAt),
        taskUpdates: json.taskUpdates.map((tu) => ({
          ...tu,
          createdAt: new Date(tu.createdAt),
        })),
      });
    } else {
      railsToast({ info: json.error }, status);
    }
    setLoadingTask(false);
  };

  const navigateToTask = () => {
    navigate(`/tasks/${taskId}`);
  };

  const stopSession = async () => {
    setIframeUrl(null);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sessions/${sessionId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      navigateToTask();
    } else {
      railsToast(await response.json(), response.status);
    }
  };


  const syncAppointmentTime = async () => {
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/tasks/${task.id}/sync_appointment_time`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const json = await response.json();
    const status = response.status;
    if (![200, 304].includes(status)) {
      railsToast({ info: json.error }, status);
      return false;
    } else {
      return true;
    }
  };

  const completeSession = async () => {
    setIsLoading(true);
    const newStatus = apptTimeIsChanged ? TaskStatus.AWAITING_SYNC : TaskStatus.COMPLETED
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { status: newStatus } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if (![200].includes(status)) {
      railsToast(json, status);
    } else {
      if (apptTimeIsChanged || await syncAppointmentTime()) {
        await stopSession();
      }
    }
    setIsLoading(false);
  };

  const onAppointmentUpdate = () => {
    setApptTimeIsChanged(true);
    setAppointmentSet(true);
  }

  const onConfNumUpdate = () => {
    setAppointmentSet(true);
  }

  if (loadingSession || loadingTask) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else
    return (
      <div className="container mt-4">
        <TaskPreview task={task} />
        <div className="border-bottom my-3"></div>
        <div>
          <div className="my-2">
            <AppointmentInput
              task={task}
              doInitialize={!iframeUrl}
              onUpdate={onAppointmentUpdate}
              onNullUpdate={onAppointmentUpdate}
              disabled={!iframeUrl}
            />
          </div>
          <div className="my-2">
            <ConfirmationInput
              task={task}
              doInitialize={!iframeUrl}
              taskUpdated={() => {}}
              onUpdate={onConfNumUpdate}
              disabled={!iframeUrl}
            />
          </div>
          {iframeUrl ? (
            <Button
              className="my-2 bg-danger border-danger"
              onClick={stopSession}
            >
              Cancel Session
            </Button>
          ) : (
            <Button
              className="my-2 bg-danger border-danger"
              onClick={navigateToTask}
            >
              Back
            </Button>
          )}
          {iframeUrl && (
            <Button
              className="mx-1 my-2"
              onClick={completeSession}
              disabled={isLoading || !appointmentSet}
            >
              Complete Session
            </Button>
          )}
        </div>
        <div id="player"></div>
        {iframeUrl ? (
          <>
            <iframe
              src={iframeUrl}
              sandbox="allow-same-origin allow-scripts"
              allow="clipboard-read; clipboard-write"
              style={{ width: "100%", aspectRatio: "13/9", border: "none" }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
};

export default SessionShow;
