import { Task } from "../tasks/types";
import { getShortDateTime, timezones } from "./date-time-utils";

const ZENGISTICSID = "org_2mFuk9StgND9smb2OZG7ShRhGta";
const CAPSTONEID = "org_2mLJDyH8dDHXc9CAI8WrwVnmtL0";

const pluralize = (
  count: number,
  singular: string,
  plural: string = `${singular}s`,
) => {
  return count === 1 ? singular : plural;
};

export const generateSubject = (task: Task, orgId: String) => {
  let zengisticsSubject = `Delivery Request`;
  let capstoneSubject = `PU Request`;
  let genericSubject = `Walmart Pickup Request / ID# ${task.shipment.bolNum}`;
  if (task.poNum?.length == 1) {
    genericSubject += ` / PO# ${task.poNum[0]}`;
    zengisticsSubject += ` - ${task.poNum[0]}`;
    capstoneSubject += `: ${task.poNum.length === 1 ? task.poNum[0] : task.shipment.shipmentId}`;
  }
  genericSubject += ` / PRO# ${task.shipment?.shipmentId}`;

  if (orgId == ZENGISTICSID) {
    return zengisticsSubject;
  } else if (orgId == CAPSTONEID) {
    return capstoneSubject;
  } else {
    return genericSubject;
  }
};

export const generateMessage = (task: Task, orgId: String) => {
  const scheduledAt = `${getShortDateTime(task.scheduledAt, task.timezone)} ${
    timezones[task.timezone]
  }`;
  const poCount = task.poNum?.length;
  let zengisticsMessage = `Hello,\n\nMay we please schedule the following delivery appointment.\n\n${scheduledAt}`;
  let capstoneMessage = `Hi,\n\nI would like to schedule pickup for the below:`;
  let genericMessage = `Hello,\n\nCould we schedule a pickup appointment for the `;

  pluralize(poCount, "PO");

  if (poCount > 1) {
    genericMessage += `below listed Walmart PO numbers for ${scheduledAt} or around that time?\n\nPO's:\n${task.poNum.join("\n")}`;
    zengisticsMessage += `POs: ${task.poNum.join(",")}`;
    capstoneMessage += `\n\nPOs: ${task.poNum.join(", ")}`;
  } else if (poCount > 0) {
    genericMessage += `above Walmart PO number for ${scheduledAt} or around that time?`;
    zengisticsMessage += `PO#: ${task.poNum[0]} - `;
    capstoneMessage += `\n\nPO: ${task.poNum[0]}`;
  }

  capstoneMessage += `\n\nDate and Time: ${scheduledAt}\n\nThank you,`;
  capstoneMessage += `\n\nCFM: ${task.shipment?.shipmentId}`;
  capstoneMessage += `\n\n${task.shipment?.bolNum}`;
  genericMessage += `\n\nThank you,`;
  zengisticsMessage += "\n\nThank you";

  if (orgId == ZENGISTICSID) {
    return zengisticsMessage;
  } else if (orgId == CAPSTONEID) {
    return capstoneMessage;
  } else {
    return genericMessage;
  }
};

export const generateCombineMessage = (tasks: Task[]) => {
  let message = `Hello,\n\nCould we schedule a pickup appointment for the following shipments at the below times?\n\n`;

  for (const task of tasks) {
    const scheduledAt = `${getShortDateTime(task.scheduledAt, task.timezone)} ${
      timezones[task.timezone]
    }`;

    if (task.poNum?.length > 1)
      message +=
        ` - (Multiple POs) ID# ${task.shipment.bolNum} / PRO# ${task.shipment.shipmentId} ` +
        `- ${scheduledAt} or around that time\n\t- PO# ${task.poNum.join("\n\t- PO# ")}\n`;
    else
      message +=
        ` - ID# ${task.shipment.bolNum}${task.poNum?.length == 1 ? ` / PO# ${task.poNum[0]}` : ""} ` +
        `/ PRO# ${task.shipment.shipmentId} - ${scheduledAt} or around that time\n`;
  }
  return message;
};

export const convertBodyToHtml = (body: string) => {
  return (
    "<span>" +
    body
      .replace(/\n/g, "</span><br><span>")
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
      .replace(/\s/g, "&nbsp;") +
    "</span>"
  );
};

export const listNotes = (notes: string) => {
  return notes?.startsWith("[")
    ? JSON.parse(notes)
        .flat()
        .filter((note) => note)
    : [notes];
};

export const isPortalUpdate = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};
