import { HumanizeType } from "@/components/tasks/show/humanize-types";
import TaskTypePill from "@/components/tasks/show/task-type-pill";
import { Task, TaskContact } from "@/components/tasks/types";
import { formatDateTime } from "@/components/utils/date-time-utils";
import { listNotes } from "@/components/utils/task-utils";
import { IconExternalLink } from "@tabler/icons-react";
import { OverlayTrigger, Stack } from "react-bootstrap";
import PONumber from "./po-number";
import popoverList from "./popover-list";
import { ContactType, Portal } from "@/components/contacts/type";

interface TaskPreviewProps {
  task: Task;
}

const TaskPreview: React.FC<TaskPreviewProps> = ({ task }) => {
  const contact = task.contacts
    .filter((contact: TaskContact) => contact.contactType == ContactType.PORTAL)
    .pop();
  const contact_details = JSON.parse(contact.contact) as Portal;
  return (
    <section>
      <Stack direction="horizontal" gap={2}>
        <h1 className="h2">
          Task: Stop{" "}
          {task.shipment.tasks.findIndex((t) => t.id === task.id) + 1}
        </h1>
        <TaskTypePill taskType={task.taskType} />
      </Stack>
      <div className="border-bottom my-3"></div>

      <div className="row g-4">
        {/* Stop Type */}
        <div className="col-md-4">
          <div className="fw-bold">Stop Type</div>
          <div>
            <HumanizeType type={task.stopType} />
          </div>
        </div>

        {!task.shipment.tasks.findIndex((t) => t.id === task.id) && (
          <>
            {/* Distance */}
            <div className="col-md-4">
              <div className="fw-bold">Distance</div>
              <div>{task.shipment.billDistance}</div>
            </div>

            {/* BOL */}
            <div className="col-md-4">
              <div className="fw-bold">BOL</div>
              <div>{task.shipment.bolNum}</div>
            </div>
          </>
        )}

        {/* Location */}
        <div className="col-md-4">
          <div className="fw-bold d-flex align-items-center">
            Location
            <a
              href={`/facilities/${task.facilityId}`}
              target="_blank"
              className="ms-2"
            >
              <IconExternalLink className="text-muted" />
            </a>
          </div>
          <div>
            {task.locationName}
            <br />
            {task.address}
            <br />
            {task.city}, {task.state}, {task.zip_code}
          </div>
        </div>

        {/* Scheduled Date and Time */}
        <div className="col-md-4">
          <div className="fw-bold">Scheduled Date & Time</div>
          <div>{formatDateTime(task.scheduledAt)}</div>
        </div>

        {/* PO Numbers */}
        {task.poNum && task.poNum.length > 0 && (
          <div className="col-md-4">
            <div className="fw-bold">PO Number(s)</div>
            <div>
              <PONumber data={task.poNum} />
            </div>
          </div>
        )}
        <div className="col-md-4">
          <div className="fw-bold">Link</div>
          <div>{contact_details.url}</div>
        </div>
        <div className="col-md-4">
          <div className="fw-bold">User Name</div>
          <div>{contact_details.username}</div>
        </div>
        <div className="col-md-4">
          <div className="fw-bold">Password</div>
          <div>{contact_details.password}</div>
        </div>
        <div className="col-md-4">
          <div className="fw-bold">Security Question</div>
          <div>{contact_details.securityQuestion}</div>
        </div>
        <div className="col-md-4">
          <div className="fw-bold">Notes</div>
          <div>{contact_details.notes}</div>
        </div>

        {/* Location Notes */}
        <div className="col-md-4">
          <div className="fw-bold">Location Notes</div>
          <div>
            <OverlayTrigger
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popoverList(listNotes(task.locationNotes))}
            >
              <i
                role="button"
                title="Location Notes"
                className="bi bi-card-list h4"
              ></i>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TaskPreview;
