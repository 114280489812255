import { Popover } from "react-bootstrap";
import style from "./PopoverList.module.css";

export default function popoverList(items) {
  return (
    <Popover id="popover-basic" className={style.popover_lg}>
      <Popover.Body>
        <ul className="mb-0 ps-3">{items?.map((item) => <li>{item}</li>)}</ul>
      </Popover.Body>
    </Popover>
  );
}
