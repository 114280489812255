import { useAuth } from "@clerk/clerk-react";
import { FC, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  Accordion,
  Button,
  InputGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import style from "./shipments.module.css";
import TaskStatusPill from "@/components/tasks/show/task-status-pill";
import { HumanizeType } from "@/components/tasks/show/humanize-types";
import useRailsToast from "@/components/utils/use-rails-toast";
import Paging, { PagingMeta } from "@/components/layout/paging";
import {
  convertBodyToHtml,
  generateCombineMessage,
  listNotes,
} from "@/components/utils/task-utils";
import {
  IconBuildingWarehouse,
  IconCheck,
  IconFilter,
  IconLoader,
  IconProps,
  IconRefresh,
  IconX,
} from "@tabler/icons-react";
import { Facility } from "@/components/facilities/type";
import { Task, TaskStatus } from "@/components/tasks/types";
import { ContactType } from "@/components/contacts/type";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import SchedulingMethodInput from "@/components/scheduling-methods/input/scheduling_method_input";
import { UpdateType } from "@/components/task-updates/types";
import TaskContactsInput from "@/components/tasks/show/contacts/task_contacts_input";
import { AppointmentDateTimeInput } from "@/components/tasks/show/appointment/appointment_input";
import { AppointmentInputType } from "@/components/tasks/show/appointment/type";
import { isFCFS } from "@/components/utils/sched-method-utils";
import { secondsToTime, timezones } from "@/components/utils/date-time-utils";
import { Message } from "../types";
import { translateTaskStatus } from "@/components/tasks/utils/translate-task-status";
import { useStartSession } from "@/components/hooks/useStartSession";
import { canStartBrowserSession } from "@/components/sessions/utils";

const Search = ({ status }: { status?: TaskStatus }) => {
  const { getToken, orgRole } = useAuth();
  const railsToast = useRailsToast();
  const [taskStatus, setStatus] = useState<TaskStatus>(status);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [meta, setMeta] = useState<PagingMeta>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [combinedTasks, setCombinedTasks] = useState<Map<string, Task[]>>();
  const [combinedMessages, setCombinedMessages] = useState<Message[]>();
  const [updateKeys, setUpdateKeys] = useState<number[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showFail, setShowFail] = useState<boolean>(false);
  const [updateFacility, setUpdateFacility] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [contactFilter, setContactFilter] = useState<string>("");
  const [schMethFilter, setSchMethFilter] = useState<string>("");
  const [shipmentsLoading, setShipmentsLoading] =
    useState<Map<string, boolean>>();
  const [shipmentsSuccess, setShipmentsSuccess] =
    useState<Map<string, boolean>>();
  const [shipmentsFailed, setShipmentsFailed] =
    useState<Map<string, boolean>>();
  const { loading: isStartingSession, startSession } = useStartSession({
    onSuccess: () => {
      console.log("Started the session");
    },
    onError: () => {
      console.log("Failed to start the session");
    },
  });

  const fetchShipments = async (pgMeta: PagingMeta) => {
    setLoading(true);
    const accessToken = await getToken();
    let path = search?.length > 0 ? `search=${search}&` : "";
    const response = await fetch(
      `/api/v1/shipments?${path}page=${pgMeta.page}&per_page=${pgMeta.perPage}${
        pgMeta.sortBy ? `&sort_by=${pgMeta.sortBy}&sort=${pgMeta.sort}` : ""
      }${
        taskStatus ? `&status=${taskStatus}` : ""
      }${contactFilter ? `&contacts=${contactFilter}` : ""}${schMethFilter ? `&scheduling_method=${schMethFilter}` : ""}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const json = await response.json();
    const resStatus = response.status;
    if ([200, 304].includes(resStatus)) {
      setShipmentsLoading(json.items.map((sh) => [sh.id, false]));
      setShipmentsSuccess(json.items.map((sh) => [sh.id, false]));
      setShipmentsFailed(json.items.map((sh) => [sh.id, false]));
      setTasks(
        json.items
          .map((sh) =>
            sh.tasks.map((tsk) => ({
              ...tsk,
              createdAt: new Date(tsk.createdAt),
              scheduledAt: new Date(tsk.scheduledAt),
              earlyScheduledAt: new Date(
                tsk.earlyScheduledAt || tsk.scheduledAt,
              ),
              shipmentId: sh.id,
              shipment: sh,
            })),
          )
          .flat()
          .filter(
            // if agent, only show tasks for which browser session can begin
            (task: Task) =>
              orgRole != "org:agent" || canStartBrowserSession(orgRole, task),
          ) ?? [],
      );
      setMeta(json.meta);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShipments(meta);
  }, [search, taskStatus]);

  const popoverList = (items) => (
    <Popover id="popover-basic" className={style.popover_lg}>
      <Popover.Body>
        <ul className="mb-0 ps-3">{items?.map((item) => <li>{item}</li>)}</ul>
      </Popover.Body>
    </Popover>
  );

  const facilityDetails = (facility: Facility) => (
    <Popover>
      <Popover.Header as="h3">
        <a href={`/facilities/${facility.id}`}>{facility.name}</a>
      </Popover.Header>
      <Popover.Body>
        {facility.address}
        <br />
        {facility.city}, {facility.state} {facility.zipCode}
        <br />
        <br />
        <div className="fw-bold">Opening Hours</div>
        <ul className="mb-0">
          {Object.keys(facility.openingDays).map((day) => (
            <li>
              <span className="text-capitalize">{day}: </span>
              {secondsToTime(facility.openingDays[day].min)}
              <span> - </span>
              {secondsToTime(facility.openingDays[day].max)}
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  const contactsFilter = () => (
    <Popover>
      <Popover.Header as="h3">Filter contacts</Popover.Header>
      <Popover.Body>
        <Form.Control
          type="text"
          placeholder="contacts e.g. abc@example.com"
          value={contactFilter}
          onChange={(e) => setContactFilter(e.currentTarget.value)}
        />
        <Button
          variant="primary"
          onClick={() => {
            fetchShipments(meta);
          }}
          className="mt-3 mb-3 float-end"
        >
          Filter
        </Button>
      </Popover.Body>
    </Popover>
  );

  const schMethsFilter = () => (
    <Popover>
      <Popover.Header as="h3">Filter by scheduling method</Popover.Header>
      <Popover.Body>
        <Form.Select
          className="rounded-bottom-0"
          disabled={loading}
          value={schMethFilter}
          onChange={(e) => setSchMethFilter(e.target.value)}
          style={{ maxWidth: "200px" }}
        >
          <option value="portal" selected>
            Portal
          </option>
          <option value="phone">Phone</option>
          <option value="email">Email</option>
          <option value="fcfs">FCFS</option>
          <option value="fcfs_notify_email">FCFS- Notify Email</option>
          <option value="fcfs_notify_phone">FCFS- Notify Phone</option>
        </Form.Select>
        <Button
          variant="primary"
          onClick={() => {
            fetchShipments(meta);
          }}
          className="mt-3 mb-3 float-end"
        >
          Filter
        </Button>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (tasks) {
      setUpdateKeys(tasks.map(() => (updateKeys?.[0] || 0) + 1));
    }
  }, [tasks]);

  useEffect(() => {
    if (combinedTasks) {
      let messages = [...combinedTasks.entries()].map(([emails, tsks]) => {
        return {
          emails: emails,
          subject: "Schedule pickup requests (multiple shipments)",
          body: generateCombineMessage(tsks),
        };
      });
      setCombinedMessages(messages);
      if (combinedTasks.size) {
        setShow(true);
      } else {
        requestAppts(false);
      }
    } else {
      setCombinedMessages(null);
    }
  }, [combinedTasks]);

  const getMessage = (emails: string) =>
    combinedMessages?.find((msg) => msg.emails === emails);

  const setMessageSubject = (emails: string, newSubject: string) => {
    getMessage(emails).subject = newSubject;
    setCombinedMessages([...combinedMessages]);
  };

  const setMessageBody = (emails: string, newBody: string) => {
    getMessage(emails).body = newBody;
    setCombinedMessages([...combinedMessages]);
  };

  const checkContacts = () => {
    for (const task of selectedTasks.filter((tsk) =>
      tsk.contacts.some((c) =>
        [ContactType.EMAIL, ContactType.PORTAL].includes(c.contactType),
      ),
    )) {
      if (
        selectedTasks.some(
          (tsk) =>
            tsk.facilityId === task.facilityId &&
            (tsk.schedulingMethod !== task.schedulingMethod ||
              !tsk.contacts.every((c) =>
                task.contacts.some((tc) => tc.contact === c.contact),
              )),
        )
      ) {
        setShowFail(true);
        return;
      } else if (
        !task.contacts.every((c) =>
          task.facility.facilityNotes?.[0]?.contacts?.some(
            (fc) => fc.contact === c.contact,
          ),
        )
      ) {
        setShowUpdate(true);
        return;
      }
    }
    preRequestAppts();
  };

  useEffect(() => {
    if (updateFacility) {
      preRequestAppts();
    }
  }, [updateFacility]);

  const preRequestAppts = () => {
    setShowUpdate(false);
    const combined = new Map<string, Task[]>();
    for (const task of selectedTasks) {
      const sortedEmails = task.contacts
        .filter((c) => c.contactType === ContactType.EMAIL)
        .map((c) => c.contact)
        .sort()
        .join(", ");
      if (combined.has(sortedEmails)) {
        combined.get(sortedEmails).push(task);
      } else {
        combined.set(sortedEmails, [task]);
      }
    }
    for (const [emails, tsks] of combined.entries()) {
      if (tsks.length == 1 || !emails) {
        combined.delete(emails);
      }
    }
    setCombinedTasks(combined);
  };

  const isRequestApptAllowed = (task: Task) =>
    task.contacts?.some((c) =>
      [ContactType.EMAIL, ContactType.PORTAL].includes(c.contactType),
    );

  const isPoNumPresent = (task: Task) => {
    if (task.poNum != null && task.poNum?.length > 0) return true;

    railsToast({ info: "PO number is required for appointment request" }, 422);
    return false;
  };

  const requestAppts = async (combine: Boolean) => {
    setShowUpdate(false);
    setCombinedTasks(null);
    setShow(false);
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/tasks/request_appts?ids[]=${selectedTasks
        .map((t) => t.id)
        .join("&ids[]=")}&combine=${combine}&update_facility=${updateFacility}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          combined_messages: combinedMessages?.map((msg) => {
            msg.body = convertBodyToHtml(msg.body);
            return msg;
          }),
        }),
      },
    );
    let json = await response.json();
    if (response.status === 201) {
      railsToast({ info: "Email was sent!" }, response.status);
      for (let taskUpdate of json) {
        let task = selectedTasks.find((t) => t.id == taskUpdate.taskId);
        if (task) {
          task.status = TaskStatus.IN_PROGRESS;
        }
      }
      setTasks([...tasks]);
      setSelectedTasks([]);
    } else if (response.status === 500) {
      railsToast(
        { info: "Something went wrong! Please try again or contact support" },
        response.status,
      );
    } else {
      railsToast(
        {
          info:
            json.error ||
            "Something went wrong! Please try again or contact support",
        },
        response.status,
      );
    }
    setLoading(false);
    setUpdateFacility(false);
  };

  const sort = (sortBy) =>
    fetchShipments({
      ...meta,
      sortBy: sortBy,
      sort: meta.sort === "asc" ? "desc" : "asc",
    });

  const getTaskNumber = (task: Task): number => {
    let count = 0;
    for (let t of task.shipment.tasks) {
      if (t.stopType === task.stopType) {
        count++;
        if (t.id === task.id) {
          return count;
        }
      }
    }
    return count;
  };

  const syncShipment = async (task: Task) => {
    const accessToken = await getToken();
    shipmentsLoading[task.shipmentId] = true;
    setShipmentsLoading({ ...shipmentsLoading });
    const response = await fetch(`/api/v1/shipments/${task.shipmentId}/sync`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      shipmentsSuccess[task.shipmentId] = true;
      setShipmentsSuccess({ ...shipmentsSuccess });
      // this is a brute force way of updating the shipments page; we could probably do better
      fetchShipments(meta);
    } else {
      shipmentsFailed[task.shipmentId] = true;
      setShipmentsFailed({ ...shipmentsFailed });
    }
    shipmentsLoading[task.shipmentId] = false;
    setShipmentsLoading({ ...shipmentsLoading });

    // revert icon back to normal
    setTimeout(() => {
      shipmentsFailed[task.shipmentId] = false;
      setShipmentsFailed({ ...shipmentsFailed });
      shipmentsSuccess[task.shipmentId] = false;
      setShipmentsSuccess({ ...shipmentsSuccess });
    }, 3000);
  };

  type SyncIconProps = Omit<IconProps, "ref"> & {
    task: Task;
    isLoading: boolean;
    isSuccess: boolean;
    isFailed: boolean;
  };
  const SyncIcon: FC<SyncIconProps> = ({
    task,
    isLoading,
    isSuccess,
    isFailed,
    ...props
  }) => {
    if (isSuccess) {
      return <IconCheck {...props} />;
    }
    if (isFailed) {
      return <IconX {...props} />;
    }
    if (isLoading) {
      return <IconLoader className={style.loading} {...props} />;
    }
    return (
      <IconRefresh
        role="button"
        onClick={() => syncShipment(task)}
        {...props}
      />
    );
  };

  const updateSchedMethod = async (
    task: Task,
    schedMethod: SchedulingMethod,
  ): Promise<boolean> => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { schedulingMethod: schedMethod } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: "Task scheduling method has been updated!" }, status);
      if (json?.length > 0) {
        task.contacts = [];
        for (const update of json) {
          const actions = update.value1.split(" ");
          if (
            update.updateType === UpdateType.CONTACTS &&
            actions[0] == "added"
          ) {
            task.contacts.push({
              taskId: task.id,
              contact: update.value2,
              contactType: actions[1],
            });
          }
        }
      }
      task.schedulingMethod = schedMethod;
      setTasks([...tasks]);
      return true;
    } else {
      railsToast(json, status);
      return false;
    }
  };

  return (
    <>
      <h1 className="h2 text-capitalize">
        {status?.replaceAll("_", " ")} Shipments
      </h1>
      <div className="py-2">
        <InputGroup>
          {!status ? (
            <Form.Select
              className="rounded-bottom-0 w-25"
              disabled={loading}
              value={taskStatus}
              onChange={(e) => setStatus(e.target.value as TaskStatus)}
              style={{ maxWidth: "200px" }}
            >
              <option value="">All</option>
              {[
                TaskStatus.SUGGESTED,
                TaskStatus.IN_PROGRESS,
                TaskStatus.ESCALATED,
                TaskStatus.AWAITING_SYNC,
                TaskStatus.COMPLETED,
                TaskStatus.CLOSED,
              ].map((status) => (
                <option value={status}>{translateTaskStatus(status)}</option>
              ))}
            </Form.Select>
          ) : (
            <></>
          )}
          <Form.Control
            type="text"
            placeholder="Shipment ID, PO#, BOL#, or city"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </InputGroup>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : tasks.length > 0 ? (
        <>
          <Stack
            direction="horizontal"
            className="pt-2 justify-content-between align-items-start"
          >
            {selectedTasks.length ? (
              <div>
                <span className="px-2">
                  {selectedTasks.length} shipments selected
                </span>
                <Button
                  variant="outline-primary"
                  onClick={checkContacts}
                  disabled={loading}
                >
                  Request appointments
                </Button>
              </div>
            ) : (
              <div></div>
            )}
            <Paging meta={meta} onPaging={fetchShipments}></Paging>
          </Stack>
          <Table>
            <thead>
              <tr>
                <th>
                  <span
                    className={style.sort}
                    role="button"
                    onClick={() => sort("shipment_id")}
                  >
                    ID
                    {meta.sortBy === "id" ? (
                      <i
                        className={`ms-2 bi bi-caret-${meta.sort === "asc" ? "up" : "down"}-fill`}
                      ></i>
                    ) : (
                      <></>
                    )}
                  </span>
                </th>
                {
                  orgRole != "org:agent" &&
                  <>
                    <th>Distance</th>
                    <th>
                      <span
                        className={style.sort}
                        role="button"
                        onClick={() => sort("bol_num")}
                      >
                        BOL
                        {meta.sortBy === "bol_num" ? (
                          <i
                            className={`ms-2 bi bi-caret-${meta.sort === "asc" ? "up" : "down"}-fill`}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </span>
                    </th>
                    <th>
                      <Form.Check
                        type="checkbox"
                        aria-label="Select all"
                        checked={
                          selectedTasks.length ===
                          tasks.filter(
                            (task) =>
                              task.status === TaskStatus.SUGGESTED &&
                              isRequestApptAllowed(task),
                          ).length
                        }
                        onChange={(event) =>
                          setSelectedTasks(
                            event.currentTarget.checked && orgRole !== "org:agent"
                              ? tasks.filter(
                                  (task) =>
                                    task.status === TaskStatus.SUGGESTED &&
                                    isRequestApptAllowed(task),
                                )
                              : [],
                          )
                        }
                      />
                    </th>
                  </>
                }
                <th>Stops</th>
                <th>
                  <span
                    className={style.sort}
                    role="button"
                    onClick={() => sort("scheduled_at")}
                  >
                    Appointment Date
                    <span className="ms-3">Time</span>
                    {meta.sortBy === "scheduled_at" ? (
                      <i
                        className={`ms-2 bi bi-caret-${meta.sort === "asc" ? "up" : "down"}-fill`}
                      ></i>
                    ) : (
                      <></>
                    )}
                  </span>
                </th>
                {orgRole == "org:agent" && <th>Quick Action</th>}
                {orgRole != "org:agent" && (
                  <>
                    <th>Status</th>
                    <th>
                      <span
                        className={style.sort}
                        role="button"
                        onClick={() => sort("scheduling_method")}
                      >
                        Scheduling Method
                        {meta.sortBy === "scheduling_method" ? (
                          <i
                            className={`ms-2 bi bi-caret-${meta.sort === "asc" ? "up" : "down"}-fill`}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </span>
                      <OverlayTrigger
                        trigger="click"
                        rootClose={true}
                        placement="auto"
                        overlay={schMethsFilter()}
                      >
                        <IconFilter className="ms-2" />
                      </OverlayTrigger>
                    </th>
                    <th>
                      <span
                        className={style.sort}
                        role="button"
                        onClick={() => sort("contacts")}
                      >
                        Contacts
                        {meta.sortBy === "contacts" ? (
                          <i
                            className={`ms-2 bi bi-caret-${meta.sort === "asc" ? "up" : "down"}-fill`}
                          ></i>
                        ) : (
                          <></>
                        )}
                      </span>
                      <OverlayTrigger
                        trigger="click"
                        rootClose={true}
                        placement="auto"
                        overlay={contactsFilter()}
                      >
                        <IconFilter className="ms-2" />
                      </OverlayTrigger>
                    </th>
                    <th>PO number</th>
                  </>
                )}
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, ix) => (
                <tr key={task.id} className="align-middle">
                  {orgRole == "org:agent" ? (
                    <td
                    className="text-nowrap"
                  >
                    <a
                      className="me-2"
                      href={`/shipments/${task.shipmentId}`}
                    >
                      {task.shipment.vendorId || task.shipment.shipmentId}
                    </a>
                  </td>
                  ) : !task.shipment.tasks.findIndex((t) => t.id === task.id) ? (
                    <>
                      <td
                        rowSpan={task.shipment.tasks.length}
                        className="text-nowrap"
                      >
                        <a
                          className="me-2"
                          href={`/shipments/${task.shipmentId}`}
                        >
                          {task.shipment.vendorId || task.shipment.shipmentId}
                        </a>
                        <SyncIcon
                          isLoading={shipmentsLoading[task.shipmentId]}
                          isSuccess={shipmentsSuccess[task.shipmentId]}
                          isFailed={shipmentsFailed[task.shipmentId]}
                          task={task}
                        />
                      </td>
                      <td rowSpan={task.shipment.tasks.length}>
                        {task.shipment.billDistance}
                      </td>
                      <td rowSpan={task.shipment.tasks.length}>
                        {task.shipment.bolNum}
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                  {orgRole != "org:agent" &&
                    <td>
                      {!isRequestApptAllowed(task) ||
                      task.status !== TaskStatus.SUGGESTED ||
                      !task.contacts.length ? (
                        <></>
                      ) : (
                        <Form.Check
                          key={updateKeys[ix]}
                          type="checkbox"
                          aria-label="Select the shipment"
                          checked={selectedTasks.some((t) => t.id === task.id)}
                          onChange={(event) =>
                            setSelectedTasks(
                              event.currentTarget.checked
                                ? [
                                    ...selectedTasks,
                                    ...tasks.filter(
                                      (t) =>
                                        task.id === t.id &&
                                        task.status === TaskStatus.SUGGESTED &&
                                        isPoNumPresent(task),
                                    ),
                                  ]
                                : selectedTasks.filter(
                                    (t) =>
                                      task.id !== t.id &&
                                      task.status === TaskStatus.SUGGESTED,
                                  ),
                            )
                          }
                        />
                      )}
                    </td>
                  }
                  <td>
                    <span className="d-flex text-nowrap">
                      <a href={`/tasks/${task.id}`}>
                        <strong>
                          <HumanizeType type={task.stopType} /> #
                          {getTaskNumber(task)}
                        </strong>
                      </a>
                      {task.facility ? (
                        <OverlayTrigger
                          trigger="click"
                          rootClose={true}
                          placement="auto"
                          overlay={facilityDetails(task.facility)}
                        >
                          <IconBuildingWarehouse className="ms-2" />
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}
                      <br></br>
                    </span>
                    {task.city}, {task.state}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <AppointmentDateTimeInput
                        task={task}
                        type={AppointmentInputType.DATE}
                      />
                      {!isFCFS(task.schedulingMethod) ? (
                        <></>
                      ) : (
                        <AppointmentDateTimeInput
                          task={task}
                          type={AppointmentInputType.EARLY_TIME}
                          className="ms-2"
                        />
                      )}
                      <AppointmentDateTimeInput
                        task={task}
                        type={AppointmentInputType.TIME}
                        className="ms-2"
                      />
                      <div className="py-2 px-1">
                        {timezones[task.timezone]}
                      </div>
                    </div>
                  </td>
                  {orgRole == "org:agent" && (
                    <td>
                      {canStartBrowserSession(orgRole, task) && (
                        <Button
                          className="ms-auto"
                          variant="outline-primary"
                          onClick={() => startSession(task.id)}
                          // use isStartingSession to show a loading indicator
                        >
                          Start browser session
                        </Button>
                      )}
                    </td>
                  )}
                  {orgRole != "org:agent" && (
                    <>
                      <td>
                        <TaskStatusPill status={task.status} />
                      </td>
                      <td>
                        <SchedulingMethodInput
                          schedMethod={task.schedulingMethod}
                          contacts={task.contacts}
                          onUpdate={async (sm) => {
                            return await updateSchedMethod(task, sm);
                          }}
                        />
                      </td>
                      <td className="py-0">
                        <TaskContactsInput
                          key={updateKeys[ix]}
                          task={task}
                          showAll={false}
                          onUpdate={() => setTasks([...tasks])}
                        />
                      </td>
                      <td>
                        {(task.poNum?.length || 0) < 3 ? (
                          task.poNum?.map((po) => <div>{po}</div>)
                        ) : (
                          <>
                            {task.poNum?.[0]}
                            <OverlayTrigger
                              trigger="click"
                              rootClose={true}
                              placement="auto"
                              overlay={popoverList(task.poNum)}
                            >
                              <div role="button">
                                <a className="primary">more...</a>
                              </div>
                            </OverlayTrigger>
                          </>
                        )}
                      </td>
                    </>
                  )}
                  <td>
                    <OverlayTrigger
                      trigger="click"
                      rootClose={true}
                      placement="auto"
                      overlay={popoverList(listNotes(task.locationNotes))}
                    >
                      <i
                        role="button"
                        title="Location Notes"
                        className="bi bi-card-list h4"
                      ></i>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={show} onHide={() => setShow(false)} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Batch appointment requests?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Some of these requests are being sent to the same point of
              contact.
              <br />
              <br />
              {combinedTasks ? (
                [...combinedTasks.entries()].map(([emails, tsks]) => (
                  <Accordion>
                    <Accordion.Item eventKey={emails}>
                      <Accordion.Header>
                        <strong>{emails}:</strong>
                        <span className="ms-2">{tsks.length} shipments</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <InputGroup>
                          <InputGroup.Text className="rounded-bottom-0">
                            Subject:{" "}
                          </InputGroup.Text>
                          <Form.Control
                            className="rounded-bottom-0"
                            type="text"
                            value={getMessage(emails)?.subject}
                            onChange={(e) =>
                              setMessageSubject(emails, e.target.value)
                            }
                          />
                        </InputGroup>
                        <Form.Control
                          className="rounded-top-0 border-top-0"
                          style={{ height: "200px" }}
                          as="textarea"
                          disabled={loading}
                          value={getMessage(emails)?.body}
                          onChange={(e) =>
                            setMessageBody(emails, e.target.value)
                          }
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))
              ) : (
                <></>
              )}
              <br />
              Do you want to combine these requests into a single email?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => requestAppts(false)}
              >
                No
              </Button>
              <Button onClick={() => requestAppts(true)}>Yes</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showFail} onHide={() => setShowFail(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Unable to request appointments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              There are different contacts listed for the same facility, Unable
              to request appointments for the selected tasks below:
              <ul>
                {selectedTasks
                  .filter(
                    (task) =>
                      task.contacts.some(
                        (c) => c.contactType === ContactType.EMAIL,
                      ) &&
                      selectedTasks.some(
                        (tsk) =>
                          tsk.facilityId === task.facilityId &&
                          (tsk.schedulingMethod !== task.schedulingMethod ||
                            !tsk.contacts.every((c) =>
                              task.contacts.some(
                                (tc) => tc.contact === c.contact,
                              ),
                            )),
                      ),
                  )
                  .map((task) => (
                    <li>
                      ID: {task.shipment.shipmentId} - {task.city}, {task.state}
                    </li>
                  ))}
              </ul>
            </Modal.Body>
          </Modal>
          <Modal show={showUpdate} onHide={() => setShowUpdate(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Update facility contacts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Contacts has been changed, Do you want to update the facility
              contact information?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={preRequestAppts}>
                No
              </Button>
              <Button onClick={() => setUpdateFacility(true)}>Yes</Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <div className="text-center">
          <p>Type an ID above to perform a search.</p>
        </div>
      )}
    </>
  );
};

export default Search;
