import { SchedulingMethod } from "../scheduling-methods/type";
import { Task, TaskStatus } from "../tasks/types";

export const canStartBrowserSession = (orgRole: string, task: Task) => {
  return (
    orgRole === "org:agent" &&
    task.status === TaskStatus.IN_PROGRESS &&
    task.schedulingMethod == SchedulingMethod.PORTAL
  );
};
