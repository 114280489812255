import useRailsToast from "@/components/utils/use-rails-toast";
import { DirectUpload } from 'activestorage';
import { useAuth } from "@clerk/clerk-react";
import { SetStateAction, useEffect, useState } from "react";
import Trix from "trix";
import { ReactTrixRTEInput } from "react-trix-rte";
import LoadingSpinner from "@/components/utils/loading-spinner";

import { Button, Form, Stack } from "react-bootstrap";

const EmailSignatureShow = () => {
  const [emailSignatureContent, setEmailSignatureContent] = useState<string>("");
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [fileURL, setFileURL] = useState<string>(null);
  const [uploadFileId, setUploadFileId] = useState<number>(null);
  const [trixEditor, setTrixEditor] = useState<any>();

  const fetchEmailSignature = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch("/api/v1/users/current", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setEmailSignatureContent(json.user.emailSignature);
    } else {
      railsToast({ info: json.error || "Something went wrong, please reload or contact support!" }, status)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmailSignature();
  }, []);

  useEffect(() => {
    if (trixEditor) {
      let att = trixEditor.composition.attachments.find((att) => {
        return att.id === uploadFileId;
      });
      var attributes = {
        url: fileURL,
        href: fileURL + "?content-disposition=attachment"
      }
      att.setAttributes(attributes);
      att.setUploadProgress(100);
      console.log('file url is set: ' + fileURL);
    }
  }, [fileURL]);

  const onSubmit = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch("/api/v1/user", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user: { emailSignatureContent: emailSignatureContent } }),
    });

    const status = await response.status;
    if ([200, 204].includes(status)) {
      railsToast({ info: "Email Signature has been saved!" }, 200)
    } else {
      const json = await response.json();
      railsToast({ info: json.error || "Something went wrong, please try again!" }, status)
    }
    setLoading(false);
  }

  function handleChange(_event: any, newValue: SetStateAction<string>) {
    setEmailSignatureContent(newValue);
  }

  const handleOnAttachmentAdd = (event: any) => {
    let trixEditor = event.target.editor;
    setTrixEditor(trixEditor);
    setUploadFileId(event.attachment.id);

    const upload = new DirectUpload(
      event.attachment.file,
      '/rails/active_storage/direct_uploads', // This url is exposed by default in your app
    );

    upload.create((error, blob) => {
      if (error) {
        console.error('there is an error');
        console.error(error);
      } else {
        let signed_id = blob.signed_id;
        let url = '';
        if (blob.service_name === 'amazon') {
          url = blob.direct_upload.url.split('?')[0];
        } else {
          url = `/rails/active_storage/blobs/${signed_id}/${blob.filename}`;
        }
        console.log("Blob url: " + url);
        setFileURL(url);
      }
    });

    event.attachment.setUploadProgress(50);
  }


  return (
    <>
      <Stack direction="horizontal" gap={2} className="mb-3">
        <h1 className="text-nowrap">Email Signature</h1>
      </Stack>
      {
        !loading ?
          <Stack>
              <Form.Group className="mb-3" controlId="emailSignatureForm.signature">
                <div>
                  <ReactTrixRTEInput
                    className="form-control"
                    defaultValue={emailSignatureContent}
                    isRailsDirectUpload={true}
                    onChange={handleChange}
                    onAttachmentAdd={handleOnAttachmentAdd}
                  /> 
                </div>
                <Form.Text muted>Note: if your email server appends a signature automatically, you do not need to also add one here.</Form.Text>
              </Form.Group>
              <div>
                <Button onClick={onSubmit}>Save</Button>
              </div>
          </Stack>
        : <LoadingSpinner />
      }
    </>
  );
};

export default EmailSignatureShow;
