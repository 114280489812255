import { OverlayTrigger } from "react-bootstrap";
import popoverList from "./popover-list";

function PONumber({ data }: { data?: string[] }) {
  return (
    data &&
    data.length > 0 && (
      <div>
        {(data.length || 0) < 3 ? (
          data.map((po) => <div>{po}</div>)
        ) : (
          <>
            {data[0]}
            <OverlayTrigger
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popoverList(data)}
            >
              <div role="button">
                <a className="primary">more...</a>
              </div>
            </OverlayTrigger>
          </>
        )}
      </div>
    )
  );
}

export default PONumber;
