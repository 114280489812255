import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "../utils/use-rails-toast";

interface UseStartSessionOptions {
  onSuccess?: (sessionId: string) => void;
  onError?: (error: any, status: number) => void;
}

export const useStartSession = ({
  onSuccess,
  onError,
}: UseStartSessionOptions) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const railsToast = useRailsToast();

  const startSession = useCallback(
    async (taskId: string | number) => {
      try {
        setLoading(true);
        /*
        The documentation mentioned a `throwOnError?` parameter, 
        but it seems to have been removed. It's unclear whether this function throws an error.

        I assume this function could throw an error because it uses a cache-first approach 
        but makes a network request if the token in memory has expired. 

        It has to propagate any encountered error somehow, and since the `throwOnError?` 
        option has been removed from the documentation, I suspect that errors are now thrown by default.

        As a precaution, I’m wrapping the entire block. Further investigation is needed to confirm 
        this behavior and handle any thrown errors properly. For now, since handling auth issues 
        is out of scope for this task, I’m ignoring them.

        ref: https://clerk.com/docs/references/javascript/session#get-token
        */
        const accessToken = await getToken();

        const response = await fetch(`/api/v1/sessions?task_id=${taskId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          onSuccess?.(data.sessionId);
          navigate(`/tasks/${taskId}/sessions/${data.sessionId}`);
        } else {
          onError?.(data, response.status);
          railsToast(data, response.status);
        }
      } catch (error) {
        // Handle any unexpected errors
        console.error("Session start failed:", error);
      } finally {
        setLoading(false);
      }
    },
    [navigate, onSuccess, onError],
  );

  return { startSession, loading };
};
