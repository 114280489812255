export const AGENT = "agent";
export const CUSTOMER = "customer";
export const UNASSIGNED = "unassigned";
export type UserType = typeof AGENT | typeof CUSTOMER | typeof UNASSIGNED;

export interface User {
  id: string;
  type: "User";
  clerkId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailSignature: string;
  userType: UserType;
}

export interface CurrentUser extends User {
  moderator: boolean;
}
