import { useState } from "react";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Stack,
} from "react-bootstrap";
import styles from "./contacts.module.css";
import {
  getContactInputType,
  acceptableContactType,
  PHONE_PATTERN,
} from "@/components/utils/contact-utils";
import { Contact, ContactType, Portal } from "@/components/contacts/type";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import ContactView from "./contact-view";

const ContactsInput = ({
  schedMethod,
  contacts,
  disabled,
  type,
  onAdd,
  onModify,
  onRemove,
  onUpdate,
  showAll = true,
}: {
  schedMethod: SchedulingMethod;
  contacts: Contact[];
  disabled?: boolean;
  type?: ContactType;
  onAdd?: (contact: string) => Promise<Contact>;
  onModify?: (contact: Contact) => Promise<boolean>;
  onRemove?: (contactId: string) => Promise<boolean>;
  onUpdate?: () => void;
  showAll?: boolean;
}) => {
  const getContacts = () => {
    if (disabled && type !== undefined)
      return contacts.filter((c) => c.contactType === type);
    else return contacts;
  };
  const [_contacts, setContacts] = useState<Contact[]>(getContacts());
  const [inputValue, setInputValue] = useState("");
  const [show, setShow] = useState<boolean>(false);
  const [showView, setShowView] = useState<boolean>(false);
  const [contact, setContact] = useState<Contact>();
  const [portal, setPortal] = useState<Portal>();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addContact(e);
    }
  };

  const addContact = async (e) => {
    if (e.target.checkValidity()) {
      let value = inputValue.trim();
      if (e.key === "Enter") {
        e.preventDefault();
        await addingContact(value);
        e.target.form.classList.remove("was-validated");
      }
    } else {
      e.target.form.classList.add("was-validated");
    }
  };

  const updateContact = async (contact: Contact) => {
    const newPortal: string = JSON.stringify(portal);
    if (await onModify({ ...contact, contact: newPortal })) {
      contact.contact = newPortal;
      setContacts([..._contacts]);
      setInputValue("");
      if (onUpdate) {
        onUpdate();
      }
      setShow(false);
    }
  };

  const addingContact = async (contact: string) => {
    const newContact = await onAdd(contact);
    if (newContact) {
      _contacts.push(newContact);
      setContacts([..._contacts]);
      setInputValue("");
      if (onUpdate) {
        onUpdate();
      }
      setShow(false);
    }
  };

  const showPortal = (contact: Contact) => {
    setPortal(JSON.parse(contact.contact));
    setShowView(true);
  };

  const editContact = async (contact: Contact) => {
    setContact(contact);
    setPortal(JSON.parse(contact.contact) as Portal);
    setShow(true);
  };

  const removeContact = async (contact: Contact, index: number) => {
    if (await onRemove(contact.id)) {
      _contacts.splice(index, 1);
      setContacts([..._contacts]);
      if (onUpdate) {
        onUpdate();
      }
      setShow(false);
    }
    setContact(null);
  };

  const addPortal = () => {
    setPortal({} as Portal);
    setShow(true);
  };

  const savePortal = async (e) => {
    e.preventDefault();
    if (contact) {
      await updateContact(contact);
    } else {
      await addingContact(JSON.stringify(portal));
    }
  };

  const deleteContact = async () => {
    await removeContact(contact, contacts.indexOf(contact));
  };

  const cancel = () => {
    setContact(null);
    setPortal(null);
    setShow(false);
    setShowView(false);
  };

  const popoverList = () => (
    <Popover id="popover-basic" className={styles.popover_lg}>
      <Popover.Body>
        <Stack gap={2}>
          {_contacts.map((contact, index) => (
            <ContactView
              contact={contact}
              onShow={() => showPortal(contact)}
              onEdit={() => editContact(contact)}
              onRemove={() => removeContact(contact, index)}
            />
          ))}
        </Stack>
      </Popover.Body>
    </Popover>
  );

  const inputChanged = (e) => {
    portal[e.target.formTarget] = e.target.value;
    setPortal({ ...portal });
  };

  return (
    <>
      <Form>
        <div className="form-group">
          <Stack>
            <Stack direction="horizontal" className="text-nowrap">
              {_contacts.map((contact, index) =>
                showAll || index === 0 ? (
                  <ContactView
                    contact={contact}
                    onShow={() => showPortal(contact)}
                    onEdit={() => editContact(contact)}
                    onRemove={() => removeContact(contact, index)}
                  />
                ) : (
                  <></>
                ),
              )}
              {disabled ? (
                <></>
              ) : schedMethod === SchedulingMethod.PORTAL ? (
                <Button variant="outline-primary" onClick={addPortal}>
                  +
                </Button>
              ) : (
                <Form.Control
                  type={getContactInputType(schedMethod)}
                  id="contact-input"
                  className="contact-input"
                  required
                  value={inputValue}
                  pattern={
                    acceptableContactType(schedMethod) === ContactType.PHONE
                      ? PHONE_PATTERN.source
                      : null
                  }
                  placeholder="Enter a contact and press Enter"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}
            </Stack>
            {showAll || _contacts.length < 2 ? (
              <></>
            ) : (
              <OverlayTrigger
                trigger="click"
                rootClose={true}
                placement="auto"
                overlay={popoverList()}
              >
                <div role="button" className={styles.more}>
                  <a className="primary">more...</a>
                </div>
              </OverlayTrigger>
            )}
          </Stack>
        </div>
      </Form>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Form onSubmit={savePortal}>
          <Modal.Header closeButton>
            <Modal.Title>Add new portal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={portal?.name}
                formTarget="name"
                required
                onChange={inputChanged}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="url"
                value={portal?.url}
                placeholder="https://..."
                formTarget="url"
                onChange={inputChanged}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={portal?.username}
                formTarget="username"
                onChange={inputChanged}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={portal?.password}
                formTarget="password"
                onChange={inputChanged}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Security Question</Form.Label>
              <Form.Control
                type="text"
                value={portal?.securityQuestion}
                formTarget="securityQuestion"
                onChange={inputChanged}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Notes / SOP</Form.Label>
              <Form.Control
                type="text"
                value={portal?.notes}
                formTarget="notes"
                onChange={inputChanged}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {!contact ? (
              <></>
            ) : (
              <Button
                variant="outline-danger"
                className="me-auto"
                onClick={deleteContact}
              >
                Remove
              </Button>
            )}
            <Button variant="outline-secondary" onClick={cancel}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showView} onHide={() => setShowView(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Portal - {portal?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={2}>
            <span>
              <a href={portal?.url} target="_blank">
                {portal?.url}
              </a>
            </span>
            <Stack direction="horizontal" gap={1}>
              <span className="fw-bold">User Name: </span>
              <span>{portal?.username}</span>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <span className="fw-bold">Password: </span>
              <span>{portal?.password}</span>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <span className="fw-bold">Security Question: </span>
              <span>{portal?.securityQuestion}</span>
            </Stack>
            <Stack direction="horizontal" gap={1}>
              <span className="fw-bold">Notes / SOP: </span>
              <span>{portal?.notes}</span>
            </Stack>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactsInput;
