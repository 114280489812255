import { Task } from "./types";

const updateTask = async (
  task: Task,
  taskChanges,
  message: string,
  getToken: () => Promise<string>,
  railsToast: ({ info: string }, status: number) => void,
  taskUpdated: () => void,
  callBack: (result) => void,
) => {
  const accessToken = await getToken();
  const response = await fetch(`/api/v1/tasks/${task.id}`, {
    method: "PUT",
    body: JSON.stringify({ task: taskChanges }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const status = response.status;
  const json = await response.json();
  if ([200].includes(status)) {
    railsToast({ info: message }, status);
    callBack(json);
    task.taskUpdates.push(...json);
    taskUpdated();
  } else {
    railsToast(json, status);
  }
};

export default updateTask;
